const navbar = document.querySelector('#_desktop_top_menu');
const top = navbar.offsetTop;

function stickynavbar() {
  const searchWidgetToHide = document.querySelector('#search_widget');

  if (window.scrollY >= top) {
    navbar.classList.add('sticky');
    searchWidgetToHide.style.display = 'none';
  } else {
    navbar.classList.remove('sticky');
    searchWidgetToHide.style.display = 'block';
  }
}

$(document).ready(()=> {
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

  if (vw > 900) {
    window.addEventListener('scroll', stickynavbar);
  }
});
