import prestashop from 'prestashop';

const bwl = () => {
  if (prestashop.page.page_name !== 'module-blockwishlist-lists') {
    return;
  }

  const addListButton = $('a.wishlist-add-to-new.text-primary');
  addListButton
    .addClass('btn btn-secondary')
    .removeClass('text-primary')
    .css({
      lineHeight: 'inherit',
    })
  ;
};

$(document).ready(bwl);
