window.setInterval(() => {
  const backdrop = document.querySelectorAll('.modal-backdrop.fade.in');
  const qvModal = document.querySelector('[id="blockcart-modal"]');

  // S'il y a au moins 2 backdrop + blockcart-modal existe et qu'il est affiché
  if (backdrop.length > 1 && qvModal && qvModal.style.display !== 'none') {
    // Alors, on supprime une occurrence du backdrop car 1 en trop.
    backdrop[0].remove();
  }
}, 2000);
